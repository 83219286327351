import * as React from 'react';
import { Container } from 'react-grid-system';
import Layout from '../components/layout';
import Seo from '../components/seo';

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <Container>
      <div className="my-10">
        <h1>404: Not Found</h1>
        <p>
          You’ve found a page that doesn’t exist. Breathe in, and on the out
          breath, go back and try again.
        </p>
        <a
          href="/"
          className="inline-flex p-6 rounded-md duration-300 justify-center 
      border-2   text-white hover:bg-green-500 
      bg-red-500 border-red-500 hover:border-gray-700 
       hover:pointer mt-10"
          rel="noreferrer"
        >
          Home Page
        </a>
      </div>
    </Container>
  </Layout>
);

export default NotFoundPage;
